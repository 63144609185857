/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
//const React = require("react")
import $ from "jquery"

// Styles
import "./src/styles/bootstrap.min.css"
import "./src/styles/fontawesome.min.css"
import "./src/styles/slicknav.min.css"
import "./src/styles/default.css"
import "./src/styles/style.css"
import "./src/styles/nice.css"
import "./src/styles/responsive.css"

// JS
export const onInitialClientRender = () => {
  var lastScrollTop = 0
  var delta = 5
  var navbarHeight = $("header .main-menu").outerHeight() - 10

  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
  let isWindowMobile = window.innerWidth <= 995;

  if (isMobile || isWindowMobile) {
    setTimeout(function() {
      $(".event-area .owl-carousel .owl-stage").css(
        "transform",
        "translate3d(-595px, 0px, 0px)"
      )
    }, 500)
  }

  function restartCSS() {
    //Breadcrumb by default
    var breadcrumb = document.getElementsByClassName("breadcrumb-area")
    if (document.getElementsByClassName("main-submenu").length) {
      document.getElementsByClassName("header-menu")[0].style.position =
        "relative"
      if (breadcrumb.length) {
        breadcrumb[0].style.marginTop = "15px"
        breadcrumb[0].style.paddingTop = "0px"
      }
    } else {
      let header = document.getElementsByClassName("header-menu")[0]
      if (header) header.style.position = "fixed"

      let home = document.getElementsByClassName("hero-area")
      if (home.length) {
        home[0].style.marginTop = isMobile || isWindowMobile ? "120px" : "145px"
      }

      if (breadcrumb.length) {
        breadcrumb[0].style.marginTop = isMobile || isWindowMobile ? "0" : "115px"
        breadcrumb[0].style.paddingTop = "0px"
      }
    }
  }

  restartCSS()

  $(window).scroll(function(event) {
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
    let isWindowMobile = window.innerWidth <= 995;

    var st = $(this).scrollTop()
    if (Math.abs(lastScrollTop - st) <= delta) return

    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      let breadcrumb = document.getElementsByClassName("breadcrumb-area")
      if ($("header .main-submenu").length) {
        $("header .main-menu").css("display", "none")
        $("header .main-submenu").css("position", "fixed")
        if (breadcrumb.length) {
          breadcrumb[0].style.marginTop = "155px"
          breadcrumb[0].style.paddingTop = "0px"
        }
      } else {
        let home = document.getElementsByClassName("hero-area")[0]
        let header = document.getElementsByClassName("header-menu")[0]
        header.style.position = "fixed"

        if (breadcrumb.length) {
          document.getElementsByClassName(
            "breadcrumb-area"
          )[0].style.marginTop = isMobile || isWindowMobile ? "80px" : "115px"
          document.getElementsByClassName(
            "breadcrumb-area"
          )[0].style.paddingTop = "0px"
        } else if (
          header.style.position === "fixed" &&
          (home.style.marginTop !== "145px" || home.style.marginTop !== "120px")
        ) {
          home.style.marginTop = isMobile || isWindowMobile ? "120px" : "145px"
        }
      }
    } else {
      // Scroll Up
      if (st <= navbarHeight) {
        if ($("header .main-submenu").length) {
          document.getElementsByClassName("main-menu")[0].style.display =
            "block"
          document.getElementsByClassName("main-submenu")[0].style.position =
            "initial"

          let breadcrumb = document.getElementsByClassName("breadcrumb-area")
          if (breadcrumb.length) {
            breadcrumb[0].style.marginTop = "15px"
            breadcrumb[0].style.paddingTop = "0px"
          }
        }
      }
    }

    lastScrollTop = st
  })

  return ;
  /*
  $(".c-title-content p a:has(button)").css("width", "100%")
  $(".c-title-content ul li:has(ul)").css("flex-direction", "column")
  Array.from(document.querySelectorAll(".c-title-content ul li")).forEach(
    item => {
      item.style["display"] = "block"
    }
  )

    if ($(this).children().length > 0) {
      $(this).css("display", "block")
    }
  })
  */
}

export const onPreRouteUpdate = () => {
{
  var lastScrollTop = 0
  var delta = 5
  var navbarHeight = $("header .main-menu").outerHeight() - 10

  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
  let isWindowMobile = window.innerWidth <= 995;

  if (isMobile || isWindowMobile) {
    setTimeout(function() {
      $(".event-area .owl-carousel .owl-stage").css(
        "transform",
        "translate3d(-595px, 0px, 0px)"
      )
    }, 500)
  }

  function restartCSS() {
    //Breadcrumb by default
    var breadcrumb = document.getElementsByClassName("breadcrumb-area")
    if (document.getElementsByClassName("main-submenu").length) {
      document.getElementsByClassName("header-menu")[0].style.position =
        "relative"
      if (breadcrumb.length) {
        breadcrumb[0].style.marginTop = "15px"
        breadcrumb[0].style.paddingTop = "0px"
      }
    } else {
      let header = document.getElementsByClassName("header-menu")[0]
      if (header) header.style.position = "fixed"

      let home = document.getElementsByClassName("hero-area")
      if (home.length) {
        home[0].style.marginTop = isMobile || isWindowMobile ? "120px" : "145px"
      }

      if (breadcrumb.length) {
        breadcrumb[0].style.marginTop = isMobile || isWindowMobile ? "0" : "115px"
        breadcrumb[0].style.paddingTop = "0px"
      }
    }
  }

  restartCSS()

  $(window).scroll(function(event) {
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
    let isWindowMobile = window.innerWidth <= 995;

    var st = $(this).scrollTop()
    if (Math.abs(lastScrollTop - st) <= delta) return

    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      let breadcrumb = document.getElementsByClassName("breadcrumb-area")
      if ($("header .main-submenu").length) {
        $("header .main-menu").css("display", "none")
        $("header .main-submenu").css("position", "fixed")
        if (breadcrumb.length) {
          breadcrumb[0].style.marginTop = "155px"
          breadcrumb[0].style.paddingTop = "0px"
        }
      } else {
        let home = document.getElementsByClassName("hero-area")[0]
        let header = document.getElementsByClassName("header-menu")[0]
        header.style.position = "fixed"

        if (breadcrumb.length) {
          document.getElementsByClassName(
            "breadcrumb-area"
          )[0].style.marginTop = isMobile || isWindowMobile ? "80px" : "115px"
          document.getElementsByClassName(
            "breadcrumb-area"
          )[0].style.paddingTop = "0px"
        } else if (
          header.style.position === "fixed" &&
          (home.style.marginTop !== "145px" || home.style.marginTop !== "120px")
        ) {
          home.style.marginTop = isMobile || isWindowMobile ? "120px" : "145px"
        }
      }
    } else {
      // Scroll Up
      if (st <= navbarHeight) {
        if ($("header .main-submenu").length) {
          document.getElementsByClassName("main-menu")[0].style.display =
            "block"
          document.getElementsByClassName("main-submenu")[0].style.position =
            "initial"

          let breadcrumb = document.getElementsByClassName("breadcrumb-area")
          if (breadcrumb.length) {
            breadcrumb[0].style.marginTop = "15px"
            breadcrumb[0].style.paddingTop = "0px"
          }
        }
      }
    }

    lastScrollTop = st
  })

  return ;
  /*
  $(".c-title-content p a:has(button)").css("width", "100%")
  $(".c-title-content ul li:has(ul)").css("flex-direction", "column")
  Array.from(document.querySelectorAll(".c-title-content ul li")).forEach(
    item => {
      item.style["display"] = "block"
    }
  )

    if ($(this).children().length > 0) {
      $(this).css("display", "block")
    }
  }
  */
  }
}
